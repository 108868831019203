import Vue from 'vue';
import axios from 'axios';
import NProgress from 'nprogress';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import i18n from '@/plugins/i18n';
import Router from '@/plugins/Router'

Vue.use(Toast);

const financeApi = axios.create({
    baseURL: process.env.VUE_APP_ENDPOINT_FINANCE,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': i18n.locale,
        'Access-Control-Allow-Origin': '*'
    }
});

const activeCurrentButton =(value)=>{

    if(!!Vue.prototype.currentButton &&
        Vue.prototype.currentButton.disable != null &&
        !Vue.prototype.currentButton.disable){
        Vue.prototype.currentButton.disable = value;
    }
    if(!!Vue.prototype.currentButton &&
        Vue.prototype.currentButton.disable){
        Vue.prototype.currentButton.disable = value;
        if(!value){
            Vue.prototype.currentButton = null
        }
    }
}

financeApi.interceptors.request.use(
    config => {

        activeCurrentButton(true);
        NProgress.start();
        var token = localStorage.getItem('access_token');

        if (token) {
            config.headers.common['Authorization'] = token.replace(/^"+/, '').replace(/"+$/, '');
        }
        return config;
    },
    error => {
        activeCurrentButton(false);
        return Promise.reject(error);
    }
);
financeApi.interceptors.response.use(
    response => {
        NProgress.done();

        activeCurrentButton(false);
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        }
        else {
            return Promise.reject(response);
        }
    },
    error => {
        activeCurrentButton(false);
        NProgress.done()
        if (error.response.status) {
            const message = error.response.data.message;
            switch (error.response.status) {
                case 400:
                    Vue.$toast.error(i18n.t('api.' + message))
                    break;
                case 401:
                    Router.push('/logout')
                    break;
                case 403:
                    Router.push('/403')
                    break;
                case 404:
                    Router.push('/404')
                    break;
                case 427:
                    Vue.$toast.error(i18n.t('api.' + message))
                    Router.push('/logout')
                    break;
                case 429:
                    Vue.$toast.error(i18n.t('you_have_tried_too_many') + i18n.t('you_can_try_again_after_1_minute'));
                    break;
                case 500:
                    Vue.$toast.error(i18n.t('something_went_wrong'));
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);

export default financeApi;
